import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from './Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';

const CustomTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: '#FCB040',
		fontWeight: 'bold'
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: '#B2BAC2',
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#FCB040',
		},
		'&:hover fieldset': {
			borderColor: '#B2BAC2',
		},
		'&.Mui-focused fieldset': {
			borderColor: '#FCB040',
		},
	},
});

const Confirmation = (props) => {
	const row = props.row;
	const [value, setValue] = useState(1);
	const [reasonValue, setReasonValue] = useState("");

	useEffect(() => {
		setReasonValue(row.reason || "");
		setValue(row.statusId == 0 ? 1 : row.statusId);
	}, [row]);

	const handleChange = (event) => {
		setValue(event.target.value);
		if (event.target.value == row.statusId) {
			setReasonValue(row.reason);
		} else {
			setReasonValue("");
		}
	}

	const handleReasonValueChange = (event) => {
		setReasonValue(event.target.value);
	};

	const closeConfirmation = () => {
		setValue(1);
		setReasonValue("");
		props.closeConfirmation();
	}

	const processCallback = (statusId, isReasonVisible) => {
		var data = { statusId, isReasonVisible, reasonValue }
		props.processCallback(data);
		setValue(1);
		setReasonValue("");
	}

	return (
		<Dialog className="popup-task" open={props.open} aria-labelledby="form-dialog-title" style={{ width: "100%" }}>
			<div className='caregiver-header'>
				<div className="caregiver-title">
					Update Task Status
				</div>
				{/* <IoIosCloseCircle className="close-button" onClick={props.closeConfirmation} /> */}
			</div>
			<DialogContent>
				<DialogContentText style={{ marginBottom: "8px", color: "#DD8F1D", fontWeight: "bold" }}>
					Title:
				</DialogContentText>
				<div className="caregiver-content">
					{row.title}
				</div>
				<DialogContentText style={{ marginBottom: "8px", color: "#DD8F1D", fontWeight: "bold" }}>
					Description:
				</DialogContentText>
				<div className="caregiver-content">
					{row.description}
				</div>
				<DialogContentText style={{ color: "#DD8F1D", fontWeight: "bold" }}>
					Status:
				</DialogContentText>
				<FormControl>
					<RadioGroup
						column
						aria-labelledby="demo-row-radio-buttons-group-label"
						name="row-radio-buttons-group"
						onChange={handleChange}
						value={value}
					>
						{props.taskStatusConfigList.map((status, index) => {
							return (
								<>
									<FormControlLabel value={status.statusId} control={<Radio checked={value == status.statusId} value={status.statusId} />} label={status.statusName} />
								</>
							)
						})}
					</RadioGroup>
				</FormControl>
				{value != 0 ?
					<>
						{props.taskStatusConfigList.map((status, index) => {
							return (
								(status.statusId == value && status.isReasonVisible ?
									<>
										<DialogContentText style={{ marginBottom: "8px", color: "#DD8F1D", fontWeight: "bold" }}>
											Reason{status.isReasonMandatory ? <sup style={{ color: "red" }}> *</sup> : null}:
										</DialogContentText>
										<CustomTextField multiline maxRows={4} style={{ width: "100%" }} focused value={reasonValue} type="text" id="outlined-basic" variant="outlined" name="reason" onChange={handleReasonValueChange} />
									</>
									: null)
							)
						})}
					</>
					: null}
			</DialogContent>
			<DialogActions className='task-bottom'>
				{props.taskStatusConfigList.map((status, index) => {
					return (
						(status.statusId == value && status.isReasonVisible && status.isReasonMandatory && reasonValue == "" ?
							<Button label="Submit" className="confirm-success-button disabled" />
							: status.statusId == value && status.isReasonVisible && status.isReasonMandatory && reasonValue != "" ?
								<Button label="Submit" onClick={() => processCallback(value, status.isReasonVisible)} className="confirm-success-button" />
								: status.statusId == value ?
									<Button label="Submit" onClick={() => processCallback(value, status.isReasonVisible)} className="confirm-success-button" />
									: null)
					)
				})}
				<Button label="Cancel" onClick={closeConfirmation} className="confirm-cancel-button" />
			</DialogActions>
		</Dialog>
	);
};

export default Confirmation;