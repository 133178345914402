import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Confirmation from '../common/components/TaskConfirmation .js';
import * as APIService from "../common/api/index.js";
import URL from "../common/api/ConstantURL";
import AlertMessage from "../common/components/AlertMessage";
import Backdrop from '@mui/material/Backdrop';
import { IoChevronBackCircleSharp } from "react-icons/io5";
import ThumbDownAltRoundedIcon from '@mui/icons-material/ThumbDownAltRounded';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import { FaCheckSquare } from "react-icons/fa";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

const ResidentTask = () => {
  const [value, setValue] = useState(0);
  const [showTodoPopup, setShowTodoPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertStatus, setAlertStatusBase] = useState("");
  const [unacknowledgedClientChecklist, setUnacknowledgedClientChecklist] = useState([]);
  const [acknowledgedClientChecklist, setAcknowledgedClientChecklist] = useState([{ statusId: 1, title: "Test", description: "Test data" }, { statusId: 2, title: "Test 1", description: "Test 1 data", reason: "Client not available" }, { statusId: 3, title: "Test 2", description: "Test 2 data", reason: "Client not available" }]);
  const [clientChecklistId, setClientChecklistId] = useState(0);
  const [clientChecklistTaskType, setClientChecklistTaskType] = useState("");
  const [clientChecklistRow, setClientChecklistRow] = useState("");
  const [taskStatusConfigList, setTaskStatusConfigList] = useState([]);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    getStatusConfigurationList();
    getAllUnackChecklistOfClient();
  }, []);

  const getStatusConfigurationList = () => {
    APIService.getData(URL.getStatusConfigList)
      .then(responseData => {
        // Handle response data
        if (responseData.code == 200) {
          var data = responseData.data;
          var sortedData = data.sort((a, b) => a.displayOrder - b.displayOrder);
          setTaskStatusConfigList(sortedData);
        } else {
          setAlertStatusBase({ message: responseData.message, status: responseData.code, origin: "center" });
        }
        setLoading(false);
      })
      .catch(error => {
        // Handle errors
        setLoading(false);
        setAlertStatusBase({ message: 'Error while fetch unacknowledged checklist of caregiver', status: '400', origin: "center" });
      });
  }

  const getAllUnackChecklistOfClient = () => {
    setUnacknowledgedClientChecklist([]);
    setLoading(true);
    APIService.getData(URL.getAllUnackChecklistOfClient + '?clientId=' + sessionStorage.getItem("ResidentId"))
      .then(responseData => {
        // Handle response data
        if (responseData.code == 200) {
          setUnacknowledgedClientChecklist(responseData.data);
        } else {
          setAlertStatusBase({ message: responseData.message, status: responseData.code, origin: "center" });
        }
        setLoading(false);
      })
      .catch(error => {
        // Handle errors
        setLoading(false);
        setAlertStatusBase({ message: 'Error while fetch unacknowledged checklist of caregiver', status: '400', origin: "center" });
      });
  }

  const getAllAckChecklistOfClient = () => {
    setAcknowledgedClientChecklist([]);
    setLoading(true);
    APIService.getData(URL.getAllAckChecklistOfClient + '?clientId=' + sessionStorage.getItem("ResidentId"))
      .then(responseData => {
        // Handle response data
        if (responseData.code == 200) {
          setAcknowledgedClientChecklist(responseData.data);
        } else {
          setAlertStatusBase({ message: responseData.message, status: responseData.code, origin: "center" });
        }
        setLoading(false);
      })
      .catch(error => {
        // Handle errors
        setLoading(false);
        setAlertStatusBase({ message: 'Error while fetch unacknowledged checklist of caregiver', status: '400', origin: "center" });
      });
  }


  const handleChange = (event, newValue) => {
    console.log("newValue", newValue)
    setValue(newValue);
    if (newValue == 0) {
      getAllUnackChecklistOfClient();
    } else {
      getAllAckChecklistOfClient();
    }
    getStatusConfigurationList();
  }

  const openTodo = (e, checklist) => {
    setClientChecklistRow(checklist);
    setClientChecklistId(checklist.checklistId);
    setClientChecklistTaskType(checklist.taskType);
    setShowTodoPopup(true);
  }

  const closeTodoConfirmation = () => {
    setClientChecklistId(0);
    setClientChecklistRow("");
    setShowTodoPopup(false);
  }

  const confirmationCallback = (data) => {
    updateClientChecklist(data);
  }

  const updateClientChecklist = (data) => {
    setLoading(true);
    var requestData = { caregiverId: sessionStorage.getItem("CaregiverId"), clientId: sessionStorage.getItem("ResidentId"), checklistId: clientChecklistId, taskType: clientChecklistTaskType, checklistResponseFlag: data.statusId, reason: data.reasonValue };
    APIService.postData(URL.upateResponseOfClientChecklist, requestData)
      .then(responseData => {
        // Handle response data
        setLoading(false);
        if (responseData.code == 200) {
          closeTodoConfirmation();
          setAlertStatusBase({ message: responseData.message, status: responseData.code, origin: "center" });
          if (value == 0) {
            getAllUnackChecklistOfClient();
          } else {
            getAllAckChecklistOfClient();
          }
        } else {
          setAlertStatusBase({ message: responseData.message, status: responseData.code, origin: "center" });
        }
      })
      .catch(error => {
        // Handle errors
        setLoading(false);
        setAlertStatusBase({ message: (value == 0 ? 'Error while acknowledge caregiver checklist' : "Error while acknowledge caregiver checklist"), status: '400', origin: "center" });
      });
  }

  const closeAlert = () => {
    setAlertStatusBase("");
  }

  const handleBack = () => {
    navigate("/resident-home");
  }

  const handleClick = (event, reason) => {
    if (reason != "") {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Header />
      <div className='home-container'>
        <Backdrop
          sx={{ color: '#ffba30', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={loading}
        >
          <img src={require('../assets/btTree.png')} className='loading-img' />
        </Backdrop>
        {alertStatus ? <AlertMessage status={alertStatus.status} message={alertStatus.message} origin={alertStatus.origin} closeAlert={closeAlert} /> : null}
        <div className='home-inner-container' style={{ justifyContent: "flex-start" }}>
          <div className='heading vitals-heading'>
            <IoChevronBackCircleSharp className='vitals-preview-header' onClick={handleBack} />
            <span style={{ fontWeight: "500", paddingLeft: "0px" }}>{sessionStorage.getItem('ResidentName')}</span>
            <span></span>
          </div>
          <span style={{ padding: '0px', fontWeight: 'bold' }}>Task</span>
          <Box className="tab col-11 col-sm-11 col-md-8 col-lg-4 col-xl-4">
            <Tabs
              onChange={handleChange}
              value={value}
              aria-label="Tabs where selection follows focus"
              selectionFollowsFocus
            >
              <Tab value={0} label="To-Do" />
              <Tab value={1} label="Completed" />
            </Tabs>
          </Box>
          <div className='task-container col-11 col-sm-11 col-md-8 col-lg-4 col-xl-4'>
            {value == 0 ?
              (unacknowledgedClientChecklist.length > 0 ?
                unacknowledgedClientChecklist.map((checkList, index) => {
                  return (
                    <div style={{ justifyContent: "space-between" }}>
                      <span onClick={(e) => openTodo(e, checkList)} style={{ width: "90%" }}>{checkList.title}</span>
                      {checkList.statusId == 1 ?
                        <FaCheckSquare style={{ color: "rgb(43, 183, 43)" }} onClick={(e) => handleClick(e, checkList.reason)} />
                        : checkList.statusId == 2 ?
                          <NoAccountsIcon style={{ color: "#ea3c3c" }} onClick={(e) => handleClick(e, checkList.reason)} />
                          : checkList.statusId == 3 ?
                            <ThumbDownAltRoundedIcon style={{ color: "#ea3c3c" }} onClick={(e) => handleClick(e, checkList.reason)} />
                            : null}
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <Typography sx={{ p: 2 }}>{checkList.reason}</Typography>
                      </Popover>
                    </div>
                  )
                })
                :
                <div className='no-task'>
                  <span> No Task Available </span>
                </div>
              )

              :
              (acknowledgedClientChecklist.length > 0 ?
                acknowledgedClientChecklist.map((checkList, index) => {
                  return (
                    <div style={{ justifyContent: "space-between" }}>
                      <span onClick={(e) => openTodo(e, checkList)} style={{ width: "90%" }}>{checkList.title}</span>
                      {checkList.statusId == 1 ?
                        <FaCheckSquare style={{ color: "rgb(43, 183, 43)" }} onClick={(e) => handleClick(e, checkList.reason)} />
                        : checkList.statusId == 2 ?
                          <NoAccountsIcon style={{ color: "#ea3c3c" }} onClick={(e) => handleClick(e, checkList.reason)} />
                          : checkList.statusId == 3 ?
                            <ThumbDownAltRoundedIcon style={{ color: "#ea3c3c" }} onClick={(e) => handleClick(e, checkList.reason)} />
                            : null}
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <Typography sx={{ p: 2 }}>{checkList.reason}</Typography>
                      </Popover>
                    </div>
                  )
                })
                :
                <div className='no-task'>
                  <span> No Task Available </span>
                </div>
              )
            }
            <Confirmation
              row={clientChecklistRow}
              open={showTodoPopup}
              closeConfirmation={(data) => closeTodoConfirmation(data)}
              processCallback={(data) => confirmationCallback(data)}
              taskStatusConfigList={taskStatusConfigList}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ResidentTask;