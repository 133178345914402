import React, { useState } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import Sidebar from './Sidebar';

const Header = () => {
  const [showSideBar, setSideBar] = useState(false);
  const confirmationCallback = () => {
    setSideBar(false);
  }

  const openSideBar = () => {
    setSideBar(true);
  }

  return (
    <div className='header-container'>
      <div className='header-left'>
        {showSideBar ?
          <Sidebar showHamBergIcon={(data) => confirmationCallback(data)} />
          :
          <GiHamburgerMenu className="caregiver-header-icon" onClick={openSideBar} />
        }
        <img src={require('../assets/btTree.png')} alt="key" style={{ padding: "0px 10px" }} />
      </div>
      <div className='header-right'>
        <span>{sessionStorage.getItem('CaregiverLastname') + ", " + sessionStorage.getItem('CaregiverFirstname')}</span>
      </div>
    </div>
  );
}

export default Header;
