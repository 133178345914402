import React, { useState } from 'react';
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from '@mui/material/Tooltip';
import Button from '../common/components/Button';
import Collapse from '@mui/material/Collapse';
import { IoIosCloseCircle } from "react-icons/io";
import { MdExpandLess } from "react-icons/md";
import { MdExpandMore } from "react-icons/md";
import { FaUserNurse } from "react-icons/fa6";
import { BiTask } from "react-icons/bi";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { FaUsersCog } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import Confirmation from '../common/components/Confirmation';
import * as APIService from "../common/api/index.js";
import URL from "../common/api/ConstantURL";
import AlertMessage from "../common/components/AlertMessage";
import Backdrop from '@mui/material/Backdrop';
import { BsFillHeartPulseFill } from "react-icons/bs";

export default function SwipeableTemporaryDrawer(props) {
	const showResidentMenu = sessionStorage.getItem('CaregiverCheckinId');
	const [state, setState] = useState({
		top: false,
		left: true,
		bottom: false,
		right: false
	});

	const [residentOpen, setResidentOpen] = useState(false);
	const [caregiverOpen, setCaregiverOpen] = useState(false);
	const [showTodoPopup, setShowTodoPopup] = useState(false);
	const [showCheckoutPopup, setShowCheckoutPopup] = useState(false);
	const [alertStatus, setAlertStatusBase] = useState("");
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const handleClick = (name) => {
		if (caregiverOpen && name == 'caregiver') {
			setCaregiverOpen(false);
		} else if (!caregiverOpen && name == 'caregiver') {
			setCaregiverOpen(true);
		} else if (residentOpen && name == 'resident') {
			setResidentOpen(false);
		} else if (!residentOpen && name == 'resident') {
			setResidentOpen(true);
		}
	};

	const handleChangePage = (page) => {
		const pageName = '/' + page;
		navigate(pageName);
	}

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const openTodo = () => {
		setShowTodoPopup(true);
	}

	const openCheckoutTodo = () => {
		setShowCheckoutPopup(true);
	}

	const closeTodoConfirmation = () => {
		setShowTodoPopup(false);
	}

	const confirmationCallback = (e) => {
		e.preventDefault();
		logOut();
	}

	const closeCheckoutConfirmation = () => {
		setShowCheckoutPopup(false);
	}

	const confirmationCheckoutCallback = (e) => {
		e.preventDefault();
		setShowTodoPopup(false);
		setLoading(true);
		const data = { caregiverCheckinId: sessionStorage.getItem("CaregiverCheckinId") };

		APIService.postData(URL.caregiverCheckout, data)
			.then(responseData => {
				// Handle response data
				if (responseData.code == 200) {
					sessionStorage.removeItem('ResidentName');
					sessionStorage.removeItem('ResidentId');
					sessionStorage.removeItem('CaregiverCheckinId');
					navigate('/home')
				} else {
					setAlertStatusBase({ message: responseData.message, status: responseData.code, origin: "center" });
				}
				setLoading(false);
			})
			.catch(error => {
				// Handle errors
				setLoading(false);
				setAlertStatusBase({ message: 'Error while checkin resident', status: '400', origin: "center" });
			});
	}

	const logOut = () => {
		setLoading(true);
		sessionStorage.removeItem("ResidentInfo");
		sessionStorage.removeItem("CaregiverId");
		sessionStorage.removeItem("CaregiverCode");
		sessionStorage.removeItem("CaregiverFirstname");
		sessionStorage.removeItem("CaregiverLastname");
		sessionStorage.removeItem('ResidentName');
		sessionStorage.removeItem('ResidentId');
		sessionStorage.removeItem('CaregiverCheckinId');
		navigate('/login')
		setLoading(false);
	}

	const closeAlert = () => {
		setAlertStatusBase("");
	}

	const list = (anchor) => (
		<Box
			sx={{ width: 370, height: "100%" }}
			role="presentation"
		>
			<Backdrop
				sx={{ color: '#ffba30', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
				open={loading}
			>
				<img src={require('../assets/btTree.png')} className='loading-img' />
			</Backdrop>
			{alertStatus ? <AlertMessage status={alertStatus.status} message={alertStatus.message} origin={alertStatus.origin} closeAlert={closeAlert} /> : null}

			<List className="sidebar-header">
				<div className="logo-sidebar">
					<img src={require('../assets/footer-BTName.png')} alt="" />
				</div>
				<div className="close-icon">
					<Tooltip title="Close Menu" arrow>
						<IoIosCloseCircle className="close-icon-inner" onClick={props.showHamBergIcon} />
					</Tooltip>
				</div>
			</List>
			<List className="sidebar-menu">
				<>
					<ListItem key="Cancelled / Deleted Requests" disablePadding>
						<ListItemButton onClick={() => handleClick('caregiver')}>
							<ListItemIcon>
								<FaUserNurse className="caregiver-sidemenu-icon" />
							</ListItemIcon>
							<ListItemText primary="Caregiver" className="sidebar-menu-text" />
							{caregiverOpen ? <MdExpandLess className="caregiver-sidemenu-icon" /> : <MdExpandMore className="caregiver-sidemenu-icon" />}
						</ListItemButton>
					</ListItem>
					<Divider className="menu-line" />

					<Collapse in={caregiverOpen} timeout="auto" className="collapse" unmountOnExit>
						<ListItem key="Cancelled / Deleted Requests" disablePadding onClick={() => handleChangePage('caregiver-task')}>
							<ListItemButton>
								<ListItemIcon>
									<BiTask className="caregiver-sidemenu-icon" />
								</ListItemIcon>
								<ListItemText primary="Task" className="sidebar-menu-text" />
							</ListItemButton>
						</ListItem>
						<Divider className="menu-line" />
						{!sessionStorage.getItem('ResidentId') ?
							<>
								<ListItem key="Cancelled / Deleted Requests" disablePadding onClick={() => handleChangePage('checkin')}>
									<ListItemButton>
										<ListItemIcon>
											<FaCheckCircle className="caregiver-sidemenu-icon" />
										</ListItemIcon>
										<ListItemText primary="Check-In" className="sidebar-menu-text" />
									</ListItemButton>
								</ListItem>
								<Divider className="menu-line" />
							</>
							: null}
					</Collapse>
					{showResidentMenu != null ?
						<>

							<ListItem key="Cancelled / Deleted Requests" disablePadding onClick={() => handleClick('resident')}>
								<ListItemButton>
									<ListItemIcon>
										<FaUsersCog className="caregiver-sidemenu-icon" />
									</ListItemIcon>
									<ListItemText primary="Resident" className="sidebar-menu-text" />
									{residentOpen ? <MdExpandLess className="caregiver-sidemenu-icon" /> : <MdExpandMore className="caregiver-sidemenu-icon" />}
								</ListItemButton>
							</ListItem>
							<Divider className="menu-line" />

							<Collapse in={residentOpen} timeout="auto" className="collapse" unmountOnExit>
								<ListItem key="Cancelled / Deleted Requests" disablePadding onClick={() => handleChangePage('resident-task')}>
									<ListItemButton>
										<ListItemIcon>
											<BiTask className="caregiver-sidemenu-icon" />
										</ListItemIcon>
										<ListItemText primary="Task" className="sidebar-menu-text" />
									</ListItemButton>
								</ListItem>
								<Divider className="menu-line" />

								<ListItem key="Cancelled / Deleted Requests" disablePadding onClick={() => handleChangePage('vitals')}>
									<ListItemButton>
										<ListItemIcon>
											<BsFillHeartPulseFill className="caregiver-sidemenu-icon" />
										</ListItemIcon>
										<ListItemText primary="Vitals" className="sidebar-menu-text" />
									</ListItemButton>
								</ListItem>
								<Divider className="menu-line" />

								<ListItem key="Cancelled / Deleted Requests" disablePadding onClick={openCheckoutTodo}>
									<ListItemButton>
										<ListItemIcon>
											<IoMdLogOut className="caregiver-sidemenu-icon" />
										</ListItemIcon>
										<ListItemText primary="Check-Out" className="sidebar-menu-text" />
									</ListItemButton>
								</ListItem>
								<Divider className="menu-line" />
							</Collapse>
						</>
						: null}
				</>
			</List>
			<Confirmation
				content="Are you sure you want to logout?"
				open={showTodoPopup}
				closeConfirmation={(data) => closeTodoConfirmation(data)}
				processCallback={(data) => confirmationCallback(data)}
			/>
			<div className="header-menu-button">
				<Button name="submit" label="LOGOUT" className="ccar-logout-button" onClick={openTodo} />
			</div>
			<div className='sidebar-version'>
				Version 1.0.0
			</div>
			<Confirmation
				username={sessionStorage.getItem('ResidentName')}
				content="Are you sure you want to check-out?"
				open={showCheckoutPopup}
				closeConfirmation={(data) => closeCheckoutConfirmation(data)}
				processCallback={(data) => confirmationCheckoutCallback(data)}
			/>
		</Box>
	);

	return (
		<div>
			{["left", "right", "top", "bottom"].map((anchor) => (
				<React.Fragment key={anchor}>
					<SwipeableDrawer
						anchor={anchor}
						open={state[anchor]}
						onClose={props.showHamBergIcon}
						onOpen={toggleDrawer(anchor, true)}
					>
						{list("left")}
					</SwipeableDrawer>
				</React.Fragment>
			))}
		</div>
	);
}