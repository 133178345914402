import React from 'react';
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { BiTask } from "react-icons/bi";
import { FaCheckCircle } from "react-icons/fa";

const Home = () => {
  const showResidentMenu = sessionStorage.getItem('CaregiverCheckinId');
  const navigate = useNavigate();
  const handleChange = (e, pathname) => {
    e.preventDefault();
    navigate('/' + pathname);
  }

  return (
    <>
      <Header />
      <div className='home-container'>
        <div className='home-inner-container'>
          <div className='home-menus col-8 col-sm-8 col-md-8 col-lg-4 col-xl-4' onClick={(e) => handleChange(e, 'caregiver-task')}>
            <BiTask className="caregiver-menu-icons" />
            <span>Task</span>
          </div>
          {showResidentMenu == null ?
            <div className='home-menus col-8 col-sm-8 col-md-8 col-lg-4 col-xl-4' onClick={(e) => handleChange(e, 'checkin')}>
              <FaCheckCircle className="caregiver-menu-icons" />
              <span>Check-In</span>
            </div>
            : null}
        </div>
      </div>
    </>
  );
}

export default Home;
