import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import { BiTask } from "react-icons/bi";
import { IoMdLogOut } from "react-icons/io";
import { BsFillHeartPulseFill } from "react-icons/bs";
import Confirmation from '../common/components/Confirmation';
import * as APIService from "../common/api/index.js";
import URL from "../common/api/ConstantURL";
import AlertMessage from "../common/components/AlertMessage";
import Backdrop from '@mui/material/Backdrop';

const ResidentHome = () => {
  const navigate = useNavigate();
  const handleChange = (e, pathname) => {
    e.preventDefault();
    navigate('/' + pathname);
  }

  const [showTodoPopup, setShowTodoPopup] = useState(false);
  const [alertStatus, setAlertStatusBase] = useState("");
  const [loading, setLoading] = useState(false);

  const openTodo = () => {
    setShowTodoPopup(true);
  }

  const closeTodoConfirmation = () => {
    setShowTodoPopup(false);
  }

  const confirmationCallback = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = { caregiverCheckinId: sessionStorage.getItem("CaregiverCheckinId") };

    APIService.postData(URL.caregiverCheckout, data)
      .then(responseData => {
        // Handle response data
        if (responseData.code == 200) {
          setShowTodoPopup(false);
          sessionStorage.removeItem('ResidentName');
          sessionStorage.removeItem('ResidentId');
          sessionStorage.removeItem('CaregiverCheckinId');
          navigate('/home')
        } else {
          setAlertStatusBase({ message: responseData.message, status: responseData.code, origin: "center" });
        }
        setLoading(false);
      })
      .catch(error => {
        // Handle errors
        setLoading(false);
        setAlertStatusBase({ message: 'Error while checkin resident', status: '400', origin: "center" });
      });
  }

  const closeAlert = () => {
    setAlertStatusBase("");
  }

  return (
    <>
      <Header />
      <div className='home-container'>
        <Backdrop
          sx={{ color: '#ffba30', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={loading}
        >
          <img src={require('../assets/btTree.png')} className='loading-img' />
        </Backdrop>
        {alertStatus ? <AlertMessage status={alertStatus.status} message={alertStatus.message} origin={alertStatus.origin} closeAlert={closeAlert} /> : null}
        <div className='home-inner-container'>
          <span>{sessionStorage.getItem('ResidentName')}</span>
          <div className='home-menus col-8 col-sm-8 col-md-8 col-lg-4 col-xl-4' style={{ margin: "0px 15px 15px 15px", padding: "30px" }} onClick={(e) => handleChange(e, 'resident-task')}>
            <BiTask className="resident-menu-icons" />
            <span>Task</span>
          </div>

          <div className='home-menus col-8 col-sm-8 col-md-8 col-lg-4 col-xl-4' style={{ padding: "30px" }} onClick={(e) => handleChange(e, 'vitals')}>
            <BsFillHeartPulseFill className="resident-menu-icons" />
            <span>Vitals</span>
          </div>

          <div className='home-menus col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3' style={{ padding: "15px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "#DD8F1D", padding: "10px" }} onClick={openTodo}>
            <IoMdLogOut className="resident-menu-icons" style={{ fontSize: "50px", marginBottom: "0px", padding: "5px 0px", color: "white" }} />
            <span style={{ fontSize: "20px", color: "white", fontWeight: "bold" }}>Check-Out</span>
          </div>
        </div>
        <Confirmation
          username={sessionStorage.getItem('ResidentName')}
          content="Are you sure you want to check-out?"
          open={showTodoPopup}
          closeConfirmation={(data) => closeTodoConfirmation(data)}
          processCallback={(data) => confirmationCallback(data)}
        />
      </div>
    </>
  );
}

export default ResidentHome;
