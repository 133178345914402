import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Confirmation from '../common/components/Confirmation';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';
import Button from '../common/components/Button';
import * as APIService from "../common/api/index.js";
import URL from "../common/api/ConstantURL";
import AlertMessage from "../common/components/AlertMessage";
import Backdrop from '@mui/material/Backdrop';
import { IoChevronBackCircleSharp } from "react-icons/io5";

const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#FCB040',
    fontWeight: 'bold'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FCB040',
      borderRadius: '30px'

    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FCB040',
    },
  },
});

const CheckIn = () => {
  const navigate = useNavigate();
  const [showTodoPopup, setShowTodoPopup] = useState(false);
  const [showResidentValue, setShowResidentValue] = useState(null);
  const [showResidentList, setShowResidentList] = useState([]);
  const [showResidentId, setShowResidentId] = useState(0);
  const [alertStatus, setAlertStatusBase] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAssociatedClientsForCaregiver();
  }, []);

  const getAssociatedClientsForCaregiver = () => {
    setShowResidentList([]);
    setLoading(true);
    APIService.getData(URL.getAssociatedClientsByCaregiverId + '?caregiverId=' + sessionStorage.getItem("CaregiverId"))
      .then(responseData => {
        // Handle response data
        if (responseData.code == 200) {
          var updatedResidentList = [];
          if (responseData.data.length > 0) {
            responseData.data.forEach(resident => {
              var residentObj = {}
              residentObj.label = resident.clientName;
              residentObj.id = resident.clientId;
              updatedResidentList.push(residentObj);
            });
            setShowResidentList(updatedResidentList)
          } else {
            setShowResidentList([]);
          }
        } else {
          setAlertStatusBase({ message: responseData.message, status: responseData.code, origin: "center" });
        }
        setLoading(false);
      })
      .catch(error => {
        // Handle errors
        setLoading(false);
        setAlertStatusBase({ message: 'Error while fetch associated clients for caregiver', status: '400', origin: "center" });
      });
  }

  const openTodo = () => {
    setShowTodoPopup(true);
  }

  const closeTodoConfirmation = () => {
    setShowTodoPopup(false);
  }

  const confirmationCallback = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = { caregiverId: sessionStorage.getItem("CaregiverId"), clientId: showResidentId };

    APIService.postData(URL.caregiverCheckin, data)
      .then(responseData => {
        // Handle response data
        setLoading(false);
        if (responseData.code == 200) {
          setShowTodoPopup(false);
          sessionStorage.setItem('ResidentName', showResidentValue);
          sessionStorage.setItem('ResidentId', showResidentId);
          sessionStorage.setItem('CaregiverCheckinId', responseData.data.caregiverCheckinDetailsId);
          navigate('/resident-home')
        } else {
          setAlertStatusBase({ message: responseData.message, status: responseData.code, origin: "center" });
        }
      })
      .catch(error => {
        // Handle errors
        setLoading(false);
        setAlertStatusBase({ message: 'Error while checkin resident', status: '400', origin: "center" });
      });
  }

  const handleChangeResident = (resident) => {
    if (resident != null) {
      setShowResidentValue(resident.label);
      setShowResidentId(resident.id);
    } else {
      setShowResidentValue(null);
      setShowResidentId(0);
    }
  }

  const closeAlert = () => {
    setAlertStatusBase("");
  }

  const handleBack = () => {
    navigate("/home");
  }

  return (
    <>
      <Header />
      <div className='home-container'>
        <Backdrop
          sx={{ color: '#ffba30', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={loading}
        >
          <img src={require('../assets/btTree.png')} className='loading-img' />
        </Backdrop>
        {alertStatus ? <AlertMessage status={alertStatus.status} message={alertStatus.message} origin={alertStatus.origin} closeAlert={closeAlert} /> : null}
        <div className='home-inner-container' style={{ justifyContent: "flex-start" }}>
          <div className='heading vitals-heading'>
            <IoChevronBackCircleSharp className='vitals-preview-header' onClick={handleBack} />
            <span style={{ fontWeight: 'bold' }}>Check-In</span>
            <span></span>
          </div>
          {showResidentList.length > 0 ?
            <>
              <div>Select the resident you want to check-in:</div>
              <div className='col-11 col-sm-11 col-md-8 col-lg-4 col-xl-4 checkin-input'>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={showResidentList}
                  sx={{ width: 300 }}
                  onChange={(event, resident) => handleChangeResident(resident)}
                  renderInput={(params) => <CustomTextField {...params} label="Select Resident" />}
                />
              </div>
            </>
            :
            <div className='no-task resident-no-task'>
              <span> No Residents Available </span>
            </div>
          }
          {showResidentList.length > 0 ?
            <div className="row" style={{ marginTop: "15px", display: "flex", justifyContent: "center" }}>
              <Button name="submit" label="Check-In" className={showResidentValue == null ? "disabled caregiver-submit-button" : "caregiver-submit-button"} onClick={openTodo} disabled={showResidentValue == null ? true : false} style={{ padding: "10px" }} />
            </div>
            : null}

          <Confirmation
            username={showResidentValue}
            content="Are you sure you want to check-in?"
            open={showTodoPopup}
            closeConfirmation={(data) => closeTodoConfirmation(data)}
            processCallback={(data) => confirmationCallback(data)}
          />
        </div>
      </div>
    </>
  );
}

export default CheckIn;
