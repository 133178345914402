var caregiverService = "https://hcc.cg.bridgetreedcc.com/homecare/appadmin/caregiver";
var checkListService = "https://hcc.cg.bridgetreedcc.com/homecare/appadmin/device/checklist";
var vitalService = "https://hcc.cg.bridgetreedcc.com/homecare/appadmin/vitals";
var statusService = "https://hcc.cg.bridgetreedcc.com/homecare/appadmin/status";

export default {
	caregiverLogin: caregiverService + "/caregiverLogin.do",
	caregiverCheckin: caregiverService + "/caregiverCheckin.do",
	caregiverCheckout: caregiverService + "/caregiverCheckout.do",
	getAllUnackChecklistOfCaregiver: checkListService + "/getAllUnackChecklistOfCaregiver.do",
	getAllAckChecklistOfCaregiver: checkListService + "/getAllAckChecklistOfCaregiver.do",
	upateResponseOfCaregiverChecklist: checkListService + "/upateResponseOfCaregiverChecklist.do",
	getAllUnackChecklistOfClient: checkListService + "/getAllUnackChecklistOfClient.do",
	getAllAckChecklistOfClient: checkListService + "/getAllAckChecklistOfClient.do",
	upateResponseOfClientChecklist: checkListService + "/upateResponseOfClientChecklist.do",
	getAssociatedClientsByCaregiverId: caregiverService + "/getAssociatedClientsByCaregiverId.do",
	getAllVitalInfoByClientId: vitalService + "/getAllVitalInfoByClientId.do",
	addVitalInfoByCaregiver: vitalService + "/addVitalInfoByCaregiver.do",
	getStatusConfigList: statusService + "/list.do"
}