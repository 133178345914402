export async function postData(url = '', data = {}) {
  // Convert data object to URL-encoded form data
  const formData = new URLSearchParams();
  for (const key in data) {
    formData.append(key, data[key]);
  }

  // Fetch options
  const options = {
    method: 'POST',
    mode: 'cors', // Ensure cross-origin request
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formData.toString(), // Convert form data to string
  };

  try {
    // Perform fetch request and await response
    const response = await fetch(url, options);

    // Check if response is OK
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Parse response JSON
    const responseData = await response.json();

    // Return response data
    return responseData;
  } catch (error) {
    // Handle fetch errors
    console.error('There was a problem with the fetch operation:', error);
    throw error; // Re-throw error for handling in the calling code
  }
}

export async function getData(url = '') {
  try {
    // Perform fetch request and await response
    const response = await fetch(url);

    // Check if response is OK
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Parse response JSON
    const responseData = await response.json();

    // Return response data
    return responseData;
  } catch (error) {
    // Handle fetch errors
    console.error('There was a problem with the fetch operation:', error);
    throw error; // Re-throw error for handling in the calling code
  }
}

