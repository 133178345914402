import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from './Button';
import { IoIosCloseCircle } from "react-icons/io";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';

const CustomTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: '#FCB040',
		fontWeight: 'bold'
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: '#B2BAC2',
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#FCB040',
		},
		'&:hover fieldset': {
			borderColor: '#B2BAC2',
		},
		'&.Mui-focused fieldset': {
			borderColor: '#FCB040',
		},
	},
});

const Confirmation = (props) => {

	const [value, setValue] = useState("0");

	const handleChange = (event) => {
		setValue(event.target.value);
	}

	return (
		<Dialog className="popup" open={props.open} aria-labelledby="form-dialog-title" style={{ width: "100%" }}>
			<DialogContent>
				{props.title ?
					<>
						<div className='caregiver-header'>
							<div className="caregiver-title">
								{props.title}
							</div>
							<IoIosCloseCircle className="close-button" onClick={props.closeConfirmation} />
						</div>
						<div className="caregiver-content">
							{props.description}
						</div>
						<DialogContentText style={{ marginBottom: "8px" }}>
							{props.content}
						</DialogContentText>
						<FormControl style={{ marginBottom: "20px" }}>
							<RadioGroup
								column
								aria-labelledby="demo-row-radio-buttons-group-label"
								name="row-radio-buttons-group"
								onChange={handleChange}
								value={value}
							>
								<FormControlLabel value="0" control={<Radio />} label="Completed" />
								<FormControlLabel value="1" control={<Radio />} label="Declined" />
								<FormControlLabel value="2" control={<Radio />} label="Not Available" />
							</RadioGroup>
						</FormControl>
						{value != 0 ?
							<CustomTextField multiline maxRows={4} style={{ width: "100%" }} focused type="text" id="outlined-basic" label='Reason' variant="outlined" name="reason" />
							: null}
					</>
					:
					<>
						<DialogContentText>
							{props.content}
						</DialogContentText>
						<div className="caregiver-contents" style={{ textAlign: "center" }}>
							{props.username}
						</div>
					</>
				}
			</DialogContent>
			<DialogActions>
				<Button label="YES" onClick={props.processCallback} className="confirm-success-button" />
				<Button label="NO" onClick={props.closeConfirmation} className="confirm-cancel-button" />
			</DialogActions>
		</Dialog>
	);
};

export default Confirmation;